import { get, post, put } from './http'
import { USERS, USER_TRANSACTIONS, USER_SUSPEND, USER_DELETE, USER_UNSUSPEND, USER_UNDELETE, GET_FILE } from '../utils/routes'

export const getUsers = query => get(`${USERS}?${query}`)

export const getOneUser = id => get(`${USERS}/${id}`)

export const getUserTransactions = id => get(`${USER_TRANSACTIONS}/${id}`)

export const suspendUser = (payload, id) => post(`${USER_SUSPEND}/${id}`, payload)

export const deleteUser = (payload, id) => post(`${USER_DELETE}/${id}`, payload)

export const unsuspendUser = id => put(`${USER_UNSUSPEND}/${id}`)

export const updateUser = (payload, id) => put(`${USERS}/${id}`, payload)

export const undeleteUser = id => post(`${USER_UNDELETE}/${id}`)

export const getFilePath = payload => post(GET_FILE, payload)

export const searchUsers = query => get(`${USERS}/search-user?${query}`)
